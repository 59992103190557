var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "mini", type: "success" },
                on: { click: _vm.submitForm },
              },
              [_vm._v("保存 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "createPost-container" },
        [
          _c(
            "el-form",
            {
              ref: "postObj",
              staticClass: "form-container",
              attrs: { model: _vm.postObj, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "标题",
                            "label-width": "100px",
                            prop: "schemeNameRule",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "200px" },
                            attrs: { name: "name", required: "" },
                            model: {
                              value: _vm.postObj.customName,
                              callback: function ($$v) {
                                _vm.$set(_vm.postObj, "customName", $$v)
                              },
                              expression: "postObj.customName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { "label-width": "100px", label: "实例编号:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { "max-width": "200px" },
                            attrs: { type: "text" },
                            model: {
                              value: _vm.postObj.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.postObj, "code", $$v)
                              },
                              expression: "postObj.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: { "label-width": "100px", label: "发布时间:" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              format: "yyyy-MM-dd HH:mm:ss",
                              placeholder: "选择日期时间",
                            },
                            model: {
                              value: _vm.postObj.createDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.postObj, "createDate", $$v)
                              },
                              expression: "postObj.createDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "100px",
                            label: "重要性(占位):",
                          },
                        },
                        [
                          _c("el-rate", {
                            staticStyle: { "margin-top": "8px" },
                            attrs: {
                              max: 3,
                              colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
                              "low-threshold": 1,
                              "high-threshold": 3,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "100px", label: "摘要:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.postObj.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.postObj, "description", $$v)
                              },
                              expression: "postObj.description",
                            },
                          }),
                          _vm.contentShortLength
                            ? _c("span", { staticClass: "word-counter" }, [
                                _vm._v(_vm._s(_vm.contentShortLength) + "字"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.postObj.NodeDesignateType === "RUNTIME_SPECIAL_USER"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择下一个节点执行用户" } },
                    [
                      _c("select-users", {
                        attrs: {
                          placeholder: "*选择下一个节点执行用户",
                          userNames: _vm.postObj.NodeDesignateTxts,
                          users: _vm.postObj.NodeDesignates,
                        },
                        on: {
                          "update:userNames": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "update:user-names": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "users-change": _vm.usersChange,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postObj.NodeDesignateType === "RUNTIME_SPECIAL_ROLE"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择下一个节点执行角色" } },
                    [
                      _c("select-roles", {
                        attrs: {
                          placeholder: "*选择下一个节点执行角色",
                          userNames: _vm.postObj.NodeDesignateTxts,
                          roles: _vm.postObj.NodeDesignates,
                        },
                        on: {
                          "update:userNames": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "update:user-names": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "roles-change": _vm.rolesChange,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-collapse",
                {
                  staticStyle: { padding: "0 15px", background: "#fff" },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _vm.postObj.frmType == 1
                        ? _c(
                            "el-card",
                            { staticClass: "box-card" },
                            [
                              _c(
                                _vm.postObj.dbName != "" &&
                                  _vm.postObj.dbName + "Update",
                                {
                                  ref: "frmData",
                                  tag: "component",
                                  attrs: { frmData: _vm.postObj.frmData },
                                }
                              ),
                            ],
                            1
                          )
                        : _vm.postObj.frmType === 2
                        ? [
                            _c(
                              "div",
                              { staticStyle: { "padding-right": "25px" } },
                              [
                                _c("CreatedForm", {
                                  ref: "createdForm",
                                  attrs: {
                                    insite: "false",
                                    data: _vm.createdFormData,
                                    value: _vm.defaultValue,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _c("el-card", { staticClass: "box-card" }, [
                            _c("form", { ref: "frmData" }, [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.postObj.frmPreviewHtml),
                                },
                              }),
                            ]),
                          ]),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "流程图", name: "3" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "600",
                          },
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [_vm._v("流程图")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { height: "500px" } },
                        [
                          _c("CreatedFlow", {
                            ref: "createdFlow",
                            attrs: {
                              "form-template": _vm.currentForm,
                              isEdit: true,
                              isShowContent: _vm.isShowContent,
                              "scheme-content": _vm.postObj.schemeContent,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }